import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    ignoreErrors: [
        // Ignore chunk load errors, they are usually caused by bad networks
        'ChunkLoadError',
        /^Loading CSS chunk \d{1-3} failed\./,
        // Ignore errors due to old unsupported browsers
        '\'Promise\' is undefined',
    ],
});

// ?embed=1
if (window.location.search.indexOf('embed=1') !== -1) {
    // We are in a widget, wait for the widget config
    const importPromise = import('./entrypoints/WidgetEp');

    window.addEventListener('message', ({data: {id, configuration}}) => {
        if (!id || id !== 'dmg-widget-initialisation') {
            return;
        }

        importPromise.then(({default: WidgetEp}) => ReactDOM.render(
            (
                <WidgetEp configuration={configuration} />
            ),
            document.getElementById('root'),
        ));
    });

    // We are listening for the initialisation, let the parent know
    window.parent.postMessage({id: 'dmg-widget-ready'}, '*');
} else {
    import('./entrypoints/AppEp').then(({default: AppEp}) => ReactDOM.render(
        (
            <AppEp />
        ),
        document.getElementById('root'),
    ));
}

